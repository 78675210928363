import React from "react";
import "./DownloadView.scss";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { CSVLink } from "react-csv";

function DownloadModel({ isOpen, onClose, piUserActivityIndividual  }) {
  if (!isOpen) {
    return null; // Don't render the modal if it's closed
  }
  //export to csv function
  const csvHeadersRow = [
    { label: "Product Name", key: "productName" },
    { label: "Pi Transacted", key: "amount" },
    { label: "Buyer Payment Status", key: "buyerPaymentStatus" },
    { label: "Status", key: "status" },
    { label: "Transaction ID", key: "_id" },
    { label: "Pi ID", key: "piId" },
    { label: "Receving User ID", key: "recivingUid" },
  ];
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="delete Modal"
        className="modal-contents "
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <main className="delete">
          <nav className="delete__delete-nav">
            <div className="delete__delete-nav__nav-title">
              <h2>Download Invoice</h2>
            </div>

            <div className="delete__delete-nav__nav-close">
              <AiOutlineClose
                className="delete__delete-nav__nav-close__class-icon"
                onClick={onClose}
              />
            </div>
          </nav>
          <div className="delete__delete-container">
            <div className="delete__delete-container__name">
              <label>Do you wish to download the Invoice?</label>
            </div>
          </div>
          <div className="delete__okay-btn">
            <button onClick={onClose}>No</button>
            <button
              onClick={onClose}
            >
              <CSVLink
                  className="csv-button"
                  data={piUserActivityIndividual}
                  headers={csvHeadersRow}
                  filename={"Invoice.csv"}
                >
              Download CSV
                </CSVLink>
            </button>
          </div>
        </main>
      </Modal>
    </>
  );
}

export default DownloadModel;
