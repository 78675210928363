import React from "react";
import "./Settings.scss";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { AiOutlinePercentage } from "react-icons/ai";

function Settings() {
  return (
    <>
      <section className="settings">
        <div className="dashboard-menu">
          <h2 className="dash-nav">Home</h2>

          <MdKeyboardArrowRight className="dash-nav" />
          <Link to="/settings" className="link-watugot">
            <h2 className="dash-nav">Settings</h2>
          </Link>
        </div>

        <div className="settings__settings-intro">
          <h1 className="user-section__intro">Settings</h1>
        </div>

        <div className="settings__settings-container">
          <div className="settings__settings-container__users">
            <p>USERS</p>

            <div className="settings__settings-container__users__service-charge">
              <label>Service Charge</label>

              <div className="settings__settings-container__users__service-charge__input-container">
                <input type="text" />
                <AiOutlinePercentage className="settings__settings-container__users__service-charge__input-container__percentage" />
              </div>
              <p>Set</p>
            </div>
          </div>

          <div className="settings__settings-container__users--">
            <div className="settings__settings-container__users--__lisitin-fee">
              <label>24 hours featured listing fee</label>
              <input type="text" />
              <p className="set-p">Set</p>
            </div>
          </div>

          <div className="settings__settings-container__users--">
            <div className="settings__settings-container__users--__lisitin-fee">
              <label>48 hours featured listing fee</label>
              <input type="text" />
              <p>Set</p>
            </div>
          </div>

          <div className="settings__settings-container__users--">
            <div className="settings__settings-container__users--__lisitin-fee">
              <label>72 hours featured listing fee</label>
              <input type="text" />
              <p>Set</p>
            </div>
          </div>
        </div>

        <div className="settings__btns">
          <button className="settings__btns__save">Save Changes</button>
          <button className="settings__btns__cancel">Cancel</button>
        </div>
      </section>
    </>
  );
}

export default Settings;
