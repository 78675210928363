import React, { useState } from "react";
import "./Profile.scss";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Avatar from "../../assets/DefaultAvatar.png";

function Profile() {
  const [newEmail, setNewEmail] = useState();
  const [confirmEmail, setConfirmEmail] = useState();
  const [newpwd, setNewPwd] = useState();
  const [confirmpwd, setConfirmPwd] = useState();

  const handleNewEmail = (e) => {
    setNewEmail(e.target.value);
  };

  const handleConfirmEmail = (e) => {
    setConfirmEmail(e.target.value);
  };

  const handleNewPwd = (e) => {
    setNewPwd(e.target.value);
  };

  const handleConfirmPwd = (e) => {
    setConfirmPwd(e.target.value);
  };

  return (
    <>
      <section className="main-profile">
        <div className="dashboard-menu">
          <h2 className="dash-nav">Home</h2>

          <MdKeyboardArrowRight className="dash-nav" />
          <Link to="/settings" className="link-watugot">
            <h2 className="dash-nav">Profile</h2>
          </Link>
        </div>

        <div className="settings-intro">
          <h1 className="user-section__intro">Profile</h1>
        </div>

        <div className="main-profile__profile-page">
          <div className="main-profile__profile-page__profile-container">
            <div className="main-profile__profile-page__profile-container__change-email">
              <div className="main-profile__profile-page__profile-container__change-email__title">
                <h4>Change Email</h4>
              </div>

              <div className="main-profile__profile-page__profile-container__change-email__input-container">
                <div className="main-profile__profile-page__profile-container__change-email__input-container__email">
                  <label>New Email Address</label>
                  <input
                    type="email"
                    value={newEmail}
                    onChange={handleNewEmail}
                  />
                </div>

                <div className="main-profile__profile-page__profile-container__change-email__input-container__email">
                  <label>Confirm New Email Address</label>
                  <input
                    type="email"
                    value={confirmEmail}
                    onChange={handleConfirmEmail}
                  />
                </div>
              </div>

              <div className="main-profile__profile-page__profile-container__change-email__save-btn">
                <button>Save Changes</button>
              </div>
            </div>

            <div className="main-profile__profile-page__profile-container__change-email">
              <div className="main-profile__profile-page__profile-container__change-email__title">
                <h4>Change Password</h4>
              </div>

              <div className="main-profile__profile-page__profile-container__change-email__input-container">
                <div className="main-profile__profile-page__profile-container__change-email__input-container__email">
                  <label>New Password</label>
                  <input
                    type="password"
                    value={newpwd}
                    onChange={handleNewPwd}
                  />
                </div>

                <div className="main-profile__profile-page__profile-container__change-email__input-container__email">
                  <label>Confirm New Password</label>
                  <input
                    type="password"
                    value={confirmpwd}
                    onChange={handleConfirmPwd}
                  />
                </div>
              </div>

              <div className="main-profile__profile-page__profile-container__change-email__save-btn">
                <button>Save Changes</button>
              </div>
            </div>
          </div>

          <div className="main-profile__profile-page__profile-pic">
            <div className="main-profile__profile-page__profile-pic__pic-title">
              <h4>Profile</h4>
            </div>

            <div className="main-profile__profile-page__profile-pic__pic-prof">
              <div className="main-profile__profile-page__profile-pic__pic-prof__pic--">
                <img src={Avatar} alt="user avatar" />
              </div>

              <div className="main-profile__profile-page__profile-pic__pic-prof__email--">
                <p className="main-profile__profile-page__profile-pic__pic-prof__email--__username">
                  John Mathew
                </p>
                <p className="main-profile__profile-page__profile-pic__pic-prof__email--__useremail">
                  ryanwanjie@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Profile;
