export const PATHS = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  APPROVAL: "/approval",
  USERS: "/users",
  STORES: "/stores",
  WATIZENS: "/watizens",
  CATEGORIES: "/categories",
  PI_ACTIVITY: "/pi-activity-users",
  FLAGGED: "/flagged-pi-listings",
  NOTIFICATION: "/notification",
  MESSAGES: "/messages",
  FEATURED: "/featured",
  SETTINGS: "/settings",
  PERMISSIONS: "/permissions",
  BILLING_PAYMENTS: "/billing-payments",
  REPORTS: "/reports",
};
