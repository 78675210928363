import React, { useState, useEffect } from "react";
import "./ProductView.scss";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
function ProductView({ isOpen, onClose, productId }) {
  const [, /*load*/ setLoad] = useState(false);
  const [productdetails, setProductdetails] = useState([]);
  const [sellerdetails, setSellerdetails] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen === true) {
      const getUserDetails = async () => {
        try {
          setLoad(true);

          const token = Cookies.get().AdminToken;

          const response = await axios.get(
            `https://www.api.watugot.net/admin/products/${productId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "X-whitelist-user": "watugot",
              },
            }
          );

          console.log(response.data);
          // console.log(response.data.sellerData);
          const productData = response.data;
          setProductdetails(productData.productObj);
          setSellerdetails(productData.sellerData);
          setLoad(false);
        } catch (err) {
          // console.log(err);
          if (err.response?.status === 401) {
            toast.error("Not authorized");
            navigate("/");
          } else if (err.response?.status === 500) {
            toast.error("A problem with our servers, hang on");
          } else {
            // toast.error("Check Internet connection");
          }
        } finally {
          setLoad(false);
        }
      };
      getUserDetails();
    }
  }, [productId, navigate, isOpen]);

  if (!isOpen) {
    return null; // Don't render the modal if it's closed
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="product Modal"
        className="modal-contents-product"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <main className="product">
          <nav className="product__product-nav">
            <div className="product__product-nav__nav-title">
              <h2>Flagged Product Details</h2>
            </div>

            <div className="product__product-nav__nav-close">
              <AiOutlineClose
                className="product__product-nav__nav-close__class-icon"
                onClick={onClose}
              />
            </div>
          </nav>
          <div className="product__product-container">
            <div className="product__product-container__name">
              <label>Product Name</label>
              <input
                type="text"
                defaultValue={productdetails?.title}
                readOnly
              />
            </div>

            <div className="product__product-container__name">
              <label>Description</label>
              <input
                type="text"
                defaultValue={productdetails?.description}
                readOnly
              />
            </div>

            <div className="product__product-container__name">
              <label>Created At</label>
              <input
                type="text"
                defaultValue={new Date(
                  productdetails?.created_at * 1000
                ).toLocaleDateString()}
                readOnly
              />
            </div>

            <div className="product__product-container__name">
              <label>Price</label>
              <input
                type="text"
                defaultValue={productdetails?.price}
                readOnly
              />
            </div>

            <div className="product__product-container__name">
              <label>Condition</label>
              <input
                type="text"
                defaultValue={productdetails?.condition}
                readOnly
              />
            </div>
          </div>
          <div className="product__product-container">
            <div className="product__product-container__name">
              <label>Seller Name</label>
              <input type="text" defaultValue={sellerdetails?.name} readOnly />
            </div>

            <div className="product__product-container__name">
              <label>Seller's Rating</label>
              <input
                type="text"
                defaultValue={sellerdetails?.rating}
                readOnly
              />
            </div>
          </div>

          <div className="product__okay-btn">
            <button onClick={onClose}>Close</button>
          </div>
        </main>
      </Modal>
    </>
  );
}

export default ProductView;
