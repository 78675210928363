import React from "react";
import Nav from "../../components/Navbar/Nav";
import Side from "../../components/SideBar/SideBar";
import FlaggedPiListing from "../../components/Flagged/Flagged-Pi-Listing.jsx";

function Flags() {
  return (
    <>
      <div className="main-container">
        <div className="side-main-container side-main-container-responsive">
          <Side />
        </div>

        <div className="nav-dash-main-container">
          <Nav />
          <FlaggedPiListing />
        </div>
      </div>
    </>
  );
}

export default Flags;
