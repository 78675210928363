import React, { useState } from "react";
import "./Nav.css";
import { RiMenu5Fill } from "react-icons/ri";
import { AiOutlineMessage } from "react-icons/ai";
import Avatar from "../../assets/DefaultAvatar.png";
import { BiBell } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
// import Sidebars from "../sidebar/sidebar.jsx";
import Sidebars from "../SideBar/SideBar";
import { Link } from "react-router-dom";
function Nav() {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className="nav">
        <div className="main-nav">
          <div className="menu-toggle">
            <RiMenu5Fill
              className={`menu ${isOpen ? "" : "active"}`}
              onClick={handleToggleSidebar}
            />
          </div>

          <div className="nav-details">
            <div className="search">
              <input type="text" placeholder="search anything here" />

              <BsSearch className="search-icon" />
            </div>

            <div className="bell">
              <BiBell className="icon-icon" />
            </div>

            <div className="bell">
              <AiOutlineMessage className="icon-icon" />
            </div>

            <div className="avatar">
              <Link to="/profile">
                <img src={Avatar} alt="" className="avatar-img" />
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <div className={`side-import ${isOpen ? "active" : ""}`}>
        <Sidebars isOpen={isOpen} handleToggleSidebar={handleToggleSidebar} />
      </div>
    </>
  );
}

export default Nav;
