import React, { useState, useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import ReasonModel from "./ReasonView";
import PropTypes from "prop-types";
import {
  Table,
  TableContainer,
  TableRow,
  TablePagination,
  TableHead,
  TableCell,
  TableBody,
  Switch,
  Checkbox,
  TableSortLabel,
  Box,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import "./Flagged-Pi-Listing.scss";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import DeleteViewModel from "./DeleteView";
import FlaggedContact from "./FlaggedContact";
import ProductView from "./ProductView";
function FlaggedPiListing() {
  const [reasonModal, setReasonModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [productViewModal, setProductViewModal] = useState(false);
  const [flaggedContactModal, setFlaggedContactModal] = useState(false);
  const [reason, setReason] = useState("");
  const [flagId, setFlagId] = useState("");
  const [productViewId, setProductViewId] = useState("");
  const [flaggedContactId, setFlaggedContactId] = useState("");
  let path = useLocation();
  const navigate = useNavigate();
  const [, /*load*/ setLoad] = useState(false);
  const [flaggedPiListing, setFlaggedPiListing] = useState([]);
  const token = Cookies.get().AdminToken;
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);

  const getFlagged = useCallback(async () => {
    try {
      setLoad(true);

      // const token = Cookies.get().AdminToken;
      // console.log(token);

      const response = await axios.get(
        `https://www.api.watugot.net/admin/flags/${skip}/${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-whitelist-user": "watugot",
          },
        }
      );

      console.log(response.data.flags);
      const data = response.data.flags;

      setFlaggedPiListing(data);

      setLoad(false);
    } catch (err) {
      // console.log(err);
      if (err.response?.status === 401) {
        toast.error("Not authorized");
        navigate("/");
      } else if (err.response?.status === 500) {
        toast.error("A problem with our servers, hang on");
      } else {
        toast.error("Check Internet connection");
      }
    } finally {
      setLoad(false);
    }
  }, [navigate, skip, limit, token]);

  useEffect(() => {
    getFlagged();
  }, [getFlagged]);

  const OpenReasonModal = (reason) => {
    setReasonModal(true);
    setReason(reason);
  };

  const CloseReasonModal = () => {
    setReasonModal(false);
  };
  const OpenProductViewModal = (productId) => {
    setProductViewModal(true);
    setProductViewId(productId);
  };

  const CloseProductViewModal = () => {
    setProductViewModal(false);
  };

  const OpenDeleteModal = (flagId) => {
    setDeleteModal(true);
    setFlagId(flagId);
  };

  const CloseDeleteModal = () => {
    setDeleteModal(false);
    getFlagged();
  };

  const OpenFlaggedContactModal = (userId) => {
    setFlaggedContactModal(true);
    setFlaggedContactId(userId);
  };

  const CloseFlaggedContactModal = () => {
    setFlaggedContactModal(false);
  };

  //table integration
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("date");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = useCallback((order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, []);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  //table head
  const headCells = [
    {
      id: "date",
      label: "Date",
    },
    {
      id: "product.title",
      label: "Listing Name",
    },
    {
      id: "user.piData.username",
      label: "Pi Username",
    },
    {
      id: "flaggedContact",
      label: "Flagged Contact",
    },
    {
      id: "flagCountForItem",
      label: "No of Flags",
    },
    {
      id: "reason",
      label: "Reason",
    },
    {
      id: "view",
      label: "View",
    },
    {
      id: "edit",
      label: "Edit",
    },
    {
      id: "action",
      label: "Action",
    },
  ];
  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow className="table-header">
          <TableCell className="table-cell">
            <Checkbox
              // color="primary"
              className="checkbox"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "Select All",
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              className="header-label"
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  //search, sort, pagination functionality

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = flaggedPiListing.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    //  console.log(newPage);
    setPage(newPage);
    if (newPage > page) {
      const newSkip = skip + 10;
      const newLimit = limit + 10;
      setSkip(newSkip);
      setLimit(newLimit);
    } else {
      const newSkip = skip - 10;
      const newLimit = limit - 10;
      setSkip(newSkip);
      setLimit(newLimit);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const innerMemoFunc = useCallback(() => {
    const arr = stableSort(
      flaggedPiListing,
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const newRows = arr.filter((el, i) => {
      const searchMatches =
        el?.product?.title
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        el?.user?.piData?.username
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase());

      return searchMatches;
    });
    return newRows;
  }, [
    order,
    orderBy,
    page,
    rowsPerPage,
    flaggedPiListing,
    searchQuery,
    getComparator,
  ]);

  // final array of data to pass in table body
  const visibleRows = React.useMemo(() => innerMemoFunc(), [innerMemoFunc]);

  //  console.log(visibleRows)

  return (
    <>
      <section className="side-side-dash">
        <div className="dash-main-cont">
          <div className="dashboard-menu">
            <h2 className="dash-nav">Home</h2>
            <MdKeyboardArrowRight className="dash-nav" />
            <Link to="/users" className="link-watugot">
              <h2 className="dash-nav">Flagged</h2>
            </Link>
            <MdKeyboardArrowRight className="dash-nav" />

            <h2 className="dash-nav" style={{ cursor: "pointer" }}>
              Pi Listings
            </h2>
          </div>

          <div className="flagged-nav-section">
            <div className="heading">
              <h1 className="label">Flagged</h1>
            </div>

            <div className="nav-section">
              <div
                className={
                  path.pathname === "/flagged-store"
                    ? "nav-head-selected"
                    : "nav-head"
                }
              >
                <div to="#" className="nav-head-label">
                  <p>Store</p>
                </div>
              </div>

              <div
                className={
                  path.pathname === "/flagged-pi-listings"
                    ? "nav-head-selected"
                    : "nav-head"
                }
              >
                <Link to="/flagged-pi-listings" className="nav-head-label">
                  <p>Pi Listings</p>
                </Link>
              </div>

              <div
                className={
                  path.pathname === "/flagged-new-store"
                    ? "nav-head-selected"
                    : "nav-head"
                }
              >
                <div to="#" className="nav-head-label">
                  <p>New Store Items</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="entries">
          {/* <div className="entries__show">
            <div className="entries__show__text">
              <p className="entries__show__text__p">Show</p>
            </div>

            <div
              className="entries__show__group"
              style={{ marginBottom: "50px" }}
            >
              <p>10</p>
              <IoIosArrowDown />
            </div>

            <div className="entries__show__text">
              <p>entries</p>
            </div>
          </div> */}

          <div className="entries__inputs">
            <div className="entries__inputs__search">
              <input
                placeholder="Search store name or etc."
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
              <AiOutlineSearch className="entries__inputs__search__searchIcon" />
            </div>
          </div>
        </div>

        <div className="table-container">
          <TableContainer>
            <Table className="data-table">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={flaggedPiListing.length}
              />
              <TableBody>
                {visibleRows.map((rowData, index) => {
                  const isItemSelected = isSelected(rowData._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      className="table-row"
                      key={rowData._id}
                      hover
                      onClick={(event) => handleClick(event, rowData._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell
                        key="checkbox"
                        className="table__container__data-table__checkbox-cell__checkbox-cell"
                      >
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell className="table-data">
                        {new Date(rowData?.date).toLocaleDateString()}
                      </TableCell>
                      <TableCell className="table-data">
                        {rowData?.product?.title}
                      </TableCell>
                      <TableCell className="table-data">
                        {rowData?.user?.piData?.username}
                      </TableCell>
                      <TableCell>
                        <div
                          className="status-column"
                          onClick={() =>
                            OpenFlaggedContactModal(rowData?.product?.userId)
                          }
                        >
                          <div className="status-icon">
                            <AiOutlineEye className="status-icon-icon" />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="table-data">
                        {rowData?.flagCountForItem}
                      </TableCell>
                      <TableCell>
                        <div className="status-column">
                          <div
                            className="status-icon"
                            onClick={() => OpenReasonModal(rowData?.reason)}
                          >
                            <AiOutlineEye className="status-icon-icon" />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="status-column">
                          <div
                            className="status-icon"
                            onClick={() =>
                              OpenProductViewModal(rowData?.productId)
                            }
                          >
                            <AiOutlineEye className="status-icon-icon" />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <Switch
                          onClick={(e) => {
                            // ToggleSwitch(user._id);
                            // console.log(e.target.checked)
                          }}
                          // checked={user?.isActive}
                          // disabled
                          disableRipple
                        />
                      </TableCell>
                      <TableCell>
                        <div
                          className="action-column"
                          onClick={() => {
                            OpenDeleteModal(rowData?._id);
                          }}
                        >
                          <RiDeleteBin5Line className="delete-icon" />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={flaggedPiListing.length + 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        {/* <div className="pagination">
          <div className="show-entries">

            <p>
              Showing {startIndex + 1} to {Math.min(endIndex, tableData.length)}{" "}
              of {tableData.length} entries
            </p>
          </div>

          <div className="page-pagination">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pageCount}
              onPageChange={handlePageChange}
              containerClassName={"pagination-container"}
              activeClassName={"active"}
            />
          </div>
        </div> */}
        <ReasonModel
          isOpen={reasonModal}
          onClose={CloseReasonModal}
          reason={reason}
        />
        <DeleteViewModel
          isOpen={deleteModal}
          onClose={CloseDeleteModal}
          flagId={flagId}
        />
        <FlaggedContact
          isOpen={flaggedContactModal}
          onClose={CloseFlaggedContactModal}
          userId={flaggedContactId}
        />
        <ProductView
          isOpen={productViewModal}
          onClose={CloseProductViewModal}
          productId={productViewId}
        />
      </section>
    </>
  );
}

export default FlaggedPiListing;
