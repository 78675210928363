import React from "react";
import "./Contact.scss";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";

function UserContact({ isOpen, onClose, user }) {
  if (!isOpen) {
    return null; // Don't render the modal if it's closed
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Contact Modal"
        className="modal-contents-contact"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <main className="contact">
          <nav className="contact__contact-nav">
            <div className="contact__contact-nav__nav-title">
              <h2>Contact</h2>
            </div>

            <div className="contact__contact-nav__nav-close">
              <AiOutlineClose
                className="contact__contact-nav__nav-close__class-icon"
                onClick={onClose}
              />
            </div>
          </nav>
          <div className="contact__contact-container">
            <div className="contact__contact-container__name">
              <label>Name</label>
              <input
                type="text"
                defaultValue={user?.fullName ? user?.fullName : "John Doe"}
                readOnly
              />
            </div>

            <div className="contact__contact-container__name">
              <label>Pi Username</label>
              <input
                type="text"
                defaultValue={user?.pi_username ? user?.pi_username : "John Doe"}
                readOnly
              />
            </div>

            <div className="contact__contact-container__name">
              <label>Email</label>
              <input
                type="text"
                defaultValue={user?.email ? user?.email : "JohnDoe@gmail.com"}
                readOnly
              />
            </div>

            <div className="contact__contact-container__name">
              <label>Phone Number</label>
              <input
                type="text"
                defaultValue={
                  user?.phoneNumber ? user?.phoneNumber : "(405) 555-0128"
                }
                readOnly
              />
            </div>

            <div className="contact__contact-container__name">
              <label>Address</label>
              <input
                type="text"
                defaultValue={
                  user?.address1
                    ? user?.address1
                    : "8502 Preston Rd. Inglewood, Maine 98380"
                }
                readOnly
              />
            </div>
          </div>

          <div className="contact__okay-btn">
            <button  onClick={onClose}>
              Close
            </button>
          </div>
        </main>
      </Modal>
    </>
  );
}

export default UserContact;
