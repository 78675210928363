import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
// import { RegContextProvider } from "./components/context/regcontext";
import { LogContextProvider } from "./components/context/logincontext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LogContextProvider>
      <App />
    </LogContextProvider>

    <ToastContainer className="toast-container" />
  </React.StrictMode>
);
