import React, { useState, useContext } from "react";
import "./Login.css";
import { BsFillSquareFill } from "react-icons/bs";
import { AiOutlineEye, AiOutlineLoading3Quarters } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { LogContext } from "../context/logincontext";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [checkPwd, setCheckPwd] = useState(false);
  const [load, setLoad] = useState(false);
  // const [ErrMsg, setErrMsg] = useState();
  const navigate = useNavigate();

  const { /*user, loading, error,*/ dispatch } = useContext(LogContext);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const checkPassword = () => {
    setCheckPwd(!checkPwd);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // navigate("/dashboard");

    if (!email || !password) {
      toast.error("Please fill in all fields");
      return;
    }

    setLoad(true);

    dispatch({ type: "logStart" });

    try {
      const LoginData = {
        email: email,
        password: password,
      };

      const response = await axios.post(
        "https://www.api.watugot.net/admin/login",
        LoginData,
        { headers: { "X-whitelist-user": "watugot" } }
      );

      // console.log(response)

      const LoginToken = response.data.data.response.token;
      // console.log(LoginToken);

      Cookies.set("AdminToken", LoginToken);

      dispatch({ type: "logComplete", payload: response.data });

      toast.success("Login was successful");

      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);

      setLoad(false);
    } catch (error) {
      // console.log(error.response.status);
      dispatch({ type: "logFail", payload: error });

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(email)) {
        toast.error("Invalid email address. Please enter a valid email.");
        setLoad(false);

        return;
      } else if (password.length < 5) {
        toast.error(
          "Password is too short. Please enter at least 5 characters."
        );
        setLoad(false);
      }
      // else if(error.response.status === 500){
      //   toast.error("A problem with our servers, hang on");
      // }
      else {
        toast.error("Invalid Credentials");
      }
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <section className="login">
        <div className="login-container">
          <div className="container-title">
            <BsFillSquareFill className="watugot-dot" />
            <h2 className="title-watugot">Welcome to Watugot</h2>
          </div>

          <form className="container-title" onSubmit={handleSubmit}>
            <div className="input-details">
              <div className="email">
                <input
                  type="email"
                  placeholder="Email address"
                  className="email-input"
                  value={email}
                  onChange={handleEmail}
                />
              </div>

              <div className="pwd">
                <input
                  type={checkPwd ? "text" : "password"}
                  placeholder="Password"
                  className="pwd-input"
                  value={password}
                  onChange={handlePassword}
                />

                <AiOutlineEye className="pwd-icon" onClick={checkPassword} />
              </div>

              <p className="forgot">Forgot Password?</p>
            </div>

            <button type="submit">
              {load ? (
                <AiOutlineLoading3Quarters className="loading-icon" />
              ) : (
                "Sign In"
              )}
            </button>
          </form>

          <p className="account">
            Don't have an account yet?{" "}
            <Link to="/" className="sign-link">
              <span className="sign-up">Sign Up</span>
            </Link>
          </p>
        </div>
      </section>
    </>
  );
}

export default Login;
