import { LoaderStore } from "./loader.store";
import { UIStore } from "./UI.store";

class RootStore {
  UIStore;
  loaderStore;
  // authStore;

  constructor() {
    this.loaderStore = new LoaderStore();
    this.UIStore = new UIStore(this.loaderStore);
  }
}

export const store = new RootStore();
