import React, { useState } from "react";
import "./Notification.scss";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

function Notification() {
  const [type, setType] = useState();
  const [location, setLocation] = useState();
  const [title, setTitle] = useState();
  const [message, setMessage] = useState();

  const handleType = (e) => {
    setType(e.target.value);
  };

  const handleLocation = (e) => {
    setLocation(e.target.value);
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleMsg = (e) => {
    setMessage(e.target.value);
  };

  return (
    <>
      <section className="notifications">
        <div className="dashboard-menu">
          <h2 className="dash-nav">Home</h2>

          <MdKeyboardArrowRight className="dash-nav" />
          <Link to="/notifications" className="link-watugot">
            <h2 className="dash-nav">Notifications</h2>
          </Link>
        </div>

        <div className="notifications__notification-intro">
          <h1 className="notifications__notification-intro__user-section__intro">
            Notifications
          </h1>
        </div>

        <div className="notifications__notification-container">
          <div className="notifications__notification-container__type">
            <label>Type</label>

            <select value={type} onChange={handleType}>
              <option value="" disabled selected>
                Select a Type
              </option>
              <option value="category1">Users</option>
              <option value="category2">Stores</option>
              <option value="category2">Users and Stores</option>
            </select>
          </div>

          <div className="notifications__notification-container__type">
            <label>Location</label>

            <select
              value={location}
              onChange={handleLocation}
              id="location-select"
            >
              <option value="" disabled selected>
                Select a Location
              </option>
              <option value="category1">All</option>
              <option value="category2">Stores</option>
            </select>
          </div>

          <div className="notifications__notification-container__type">
            <label>Store</label>

            <select>
              <option value="" disabled selected>
                Select a Store
              </option>
              <option value="category1">All</option>
              <option value="category2">Stores</option>
            </select>
          </div>

          <div className="notifications__notification-container__type">
            <label>User</label>

            <select>
              <option value="" disabled selected>
                Select a user
              </option>
              <option value="category1">All</option>
              <option value="category2">Stores</option>
            </select>
          </div>

          <div className="notifications__notification-container__type">
            <label>Title</label>

            <input
              type="text"
              id="title-input"
              value={title}
              onChange={handleTitle}
            />
          </div>

          <div className="notifications__notification-container__type">
            <label>Message</label>

            <textarea
              id="message"
              name="message"
              rows="4"
              cols="50"
              value={message}
              onChange={handleMsg}
            ></textarea>
          </div>

          <div className="notifications__notification-container__buttons">
            <button className="notifications__notification-container__buttons__send-btn">
              Send
            </button>
            <button className="notifications__notification-container__buttons__review-btn">
              Review
            </button>
            <button className="notifications__notification-container__buttons__submit-btn">
              Submit
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Notification;
