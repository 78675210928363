import { makeAutoObservable } from "mobx";

class UIStore {
  loaderStore: any;
  sidebarCollapsed = false;

  constructor(loaderStore: any) {
    makeAutoObservable(this);
    this.loaderStore = loaderStore;
  }

  get isSidebarCollapsed() {
    return this.sidebarCollapsed;
  }

  toggleSidebar = () => {
    this.sidebarCollapsed = !this.sidebarCollapsed;
  };
}

export { UIStore };
