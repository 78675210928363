import React, { useState, useEffect, useCallback } from "react";
import "./Category.scss";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlinePlusCircle, AiOutlineLoading3Quarters } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import EditCategoryModal from "./EditCategory";
import DeleteViewModel from "./DeleteView";
import PropTypes from "prop-types";
import {
  Table,
  TableContainer,
  TableRow,
  TablePagination,
  TableHead,
  TableCell,
  TableBody,
  Checkbox,
  TableSortLabel,
  Box,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
function Categories() {
  const [parent, setParent] = useState();
  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [load, setLoad] = useState(false);
  const token = Cookies.get().AdminToken;

  const navigate = useNavigate();

  const OpenModal = (item) => {
    setSelectedItem(item);
    setModal(true);
  };

  const CloseModal = () => {
    setModal(false);
  };

  const OpenDeleteModal = (id) => {
    setCategoryId(id);
    setDeleteModal(true);
  };

  const CloseDeleteModal = () => {
    setDeleteModal(false);
    getCategories();
  };

  const handleParent = (e) => {
    setParent(e.target.value);
  };

  const Addcategory = async (e) => {
    e.preventDefault();

    if (!parent) {
      toast.error("Please fill in the field");
      return;
    }

    setLoad(true);

    try {
      const token = Cookies.get().AdminToken;

      const parentData = {
        name: parent,
      };

      const response = await axios.post(
        "https://www.api.watugot.net/admin/categories",
        parentData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-whitelist-user": "watugot",
          },
        }
      );

      // console.log(response)

      if (response?.status === 200) {
        toast.success("Category was created successfully");
        window.location.reload();
      }

      setLoad(false);
    } catch (err) {
      // console.log(err)
      if (err.response?.status === 401) {
        toast.error("Not authorized");
        navigate("/");
      } else if (err.response?.status === 500) {
        toast.error("A problem with our servers, hang on");
      } else {
        toast.error("Check Internet connection");
      }
    } finally {
      setLoad(false);
    }
  };

  const getCategories = useCallback(async () => {
    try {
      setLoad(true);
      const response = await axios.get(
        "https://www.api.watugot.net/admin/categories",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-whitelist-user": "watugot",
          },
        }
      );

      // console.log(response.data.response);
      const data = response.data.response;

      setCategories(data);

      setLoad(false);
    } catch (err) {
      // console.log(err);
      if (err.response?.status === 401) {
        toast.error("Not authorized");
        navigate("/");
      } else if (err.response?.status === 500) {
        toast.error("A problem with our servers, hang on");
      } else {
        toast.error("Check Internet connection");
      }
    } finally {
      setLoad(false);
    }
  }, [navigate, token]);

  useEffect(() => {
    getCategories();
  }, [navigate, token, getCategories]);

  // table integration
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = useCallback((order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, []);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  //table head
  const headCells = [
    {
      id: "name",
      label: "Parent Category",
    },
    {
      id: "edit",
      label: "Edit",
    },
    {
      id: "action",
      label: "Action",
    },
  ];
  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow className="table-header">
          <TableCell className="table-cell">
            <Checkbox
              // color="primary"
              className="checkbox"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "Select All",
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              className="header-label"
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  //search, sort, pagination functionality

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = categories.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categories.length) : 0;

  const innerMemoFunc = useCallback(() => {
    const arr = stableSort(categories, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    const newRows = arr.filter((el, i) => {
      const searchMatches =
        // el?.fullName?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        // el?.pi_username?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        el?.name?.toLowerCase().includes(searchQuery?.toLowerCase());

      return searchMatches;
    });
    return newRows;
  }, [
    categories,
    order,
    page,
    rowsPerPage,
    orderBy,
    searchQuery,
    getComparator,
  ]);

  // final array of data to pass in table body
  const visibleRows = React.useMemo(() => innerMemoFunc(), [innerMemoFunc]);

  return (
    <>
      <section className="categories">
        <div className="categories__container">
          <div className="categories__container__header">
            <div className="categories__container__header__header-nav">
              <h2 className="categories__container__header__header-nav__header-nav__item">
                Home
              </h2>
              <MdKeyboardArrowRight className="categories__container__header__header-nav__header-nav__icon" />
              <Link
                to="/categories"
                className="categories__container__header__header-nav__header-link"
              >
                <h2 className="categories__container__header__header-nav__header-link__h2">
                  Category
                </h2>
              </Link>
            </div>

            <div className="categories__container__header__header-title">
              <h1 className="categories__container__header__header-title__header-title__text">
                Add Category
              </h1>
            </div>
          </div>

          <div className="main-category">
            <div className="parent-category">
              <p className="category-p">CATEGORY</p>

              <div className="add-parent-category">
                <label className="add-parent-category__label">
                  Add Parent Category
                </label>
                <input
                  type="text"
                  className="add-parent-category__input"
                  required
                  value={parent}
                  onChange={handleParent}
                />
                <h2
                  className="add-parent-category__heading"
                  onClick={Addcategory}
                >
                  {load ? (
                    <AiOutlineLoading3Quarters className="loading-icon" />
                  ) : (
                    <AiOutlinePlusCircle className="add-parent-category__icon" />
                  )}{" "}
                  Add
                </h2>
              </div>
            </div>

            {/* <div className="sub-category">
              <p className="category-p">SUB CATEGORY</p>

              <div className="select-parent">
                <p className="select-parent__label">Select Parent Category</p>

                <select className="select-parent__input">
                  <option value="" disabled selected>
                    Select Category
                  </option>
                  <option value="category1">Category 1</option>
                  <option value="category2">Category 2</option>
                  <option value="category3">Category 3</option>
                </select>
              </div>

              <div className="add-sub-category">
                <label className="add-sub-category__label">
                  Add Sub Category
                </label>
                <input type="text" className="add-sub-category__input" />
                <h2
                  className="add-sub-category__heading"
                  style={{ marginLeft: "50px" }}
                >
                  <AiOutlinePlusCircle className="add-sub-category__icon" /> Add
                </h2>
              </div>
            </div> */}
          </div>

          <div className="entries_category">
            <div className="entries_category__inputs">
              <div className="entries_category__inputs__search">
                <input
                  type="text"
                  placeholder="Search store name or etc."
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                />
                <AiOutlineSearch className="entries_category__inputs__search__searchIcon" />
              </div>
            </div>
          </div>
          <div className="table__container">
            <TableContainer>
              <Table className="data-table">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={categories.length}
                />
                <TableBody className="table-body">
                  {visibleRows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan="5">
                        There are no categories at the moment
                      </TableCell>
                    </TableRow>
                  ) : (
                    visibleRows.map((item, index) => {
                      const isItemSelected = isSelected(item._id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          key={item._id}
                          className="table-row"
                          hover
                          onClick={(event) => handleClick(event, item._id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell key="checkbox" className="table-cell">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />{" "}
                          </TableCell>
                          <TableCell className="parent-row">
                            {item.name}
                          </TableCell>
                          <TableCell>
                            <button
                              className="edit-btn"
                              onClick={() => OpenModal(item)}
                            >
                              Edit
                            </button>
                          </TableCell>
                          <TableCell>
                            <div
                              className="action-column"
                              onClick={() => OpenDeleteModal(item._id)}
                            >
                              {load ? (
                                <AiOutlineLoading3Quarters className="loading-icon" />
                              ) : (
                                <RiDeleteBin5Line className="delete-icon" />
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={categories.length + 1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </section>

      <EditCategoryModal
        isOpen={modal}
        onClose={CloseModal}
        selectedItem={selectedItem}
        token={token}
      />
      <DeleteViewModel
        isOpen={deleteModal}
        onClose={CloseDeleteModal}
        categoryId={categoryId}
      />
    </>
  );
}

export default Categories;
