import React from "react";
import "./ReasonView.scss";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";

function ReasonModel({ isOpen, onClose, reason }) {
  // console.log(reason)
  if (!isOpen) {
    return null; // Don't render the modal if it's closed
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="reason Modal"
        className="modal-contents "
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <main className="reason">
          <nav className="reason__reason-nav">
            <div className="reason__reason-nav__nav-title">
              <h2>Reason</h2>
            </div>

            <div className="reason__reason-nav__nav-close">
              <AiOutlineClose
                className="reason__reason-nav__nav-close__class-icon"
                onClick={onClose}
              />
            </div>
          </nav>
          <div className="reason__reason-container">
            <div className="reason__reason-container__name">
              <label>Reason</label>
              <input
                type="text"
                defaultValue={reason ? reason : "This is sample reason"}
                readOnly
              />
            </div>
          </div>
          <div className="reason__okay-btn">
            <button  onClick={onClose}>
              Close
            </button>
          </div>
        </main>
      </Modal>
    </>
  );
}

export default ReasonModel;
