import React from "react";
import Nav from "../../../components/Navbar/Nav";
// import Side from "../../../components/sidebar/sidebar";
import Side from "../../../components/SideBar/SideBar";
import PiItemsPurchased from "../../../components/PiItems/PurchasedPiItems/PurchasedPiItems.jsx";

function PurchasePage() {
  return (
    <>
      <div className="main-container">
        <div className="side-main-container side-main-container-responsive">
          <Side />
        </div>

        <div className="nav-dash-main-container">
          <Nav />
          <PiItemsPurchased />
        </div>
      </div>
    </>
  );
}

export default PurchasePage;
