import React from "react";
import "./DeleteView.scss";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";

function DeleteViewModel({ isOpen, onClose, flagId }) {
  // console.log(reason)
  if (!isOpen) {
    return null; // Don't render the modal if it's closed
  }

  //delete API
  const handleDelete = async (flagId) => {
    try {
      const token = Cookies.get().AdminToken;
      const response = await axios.delete(
        `https://www.api.watugot.net/admin/flags/${flagId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-whitelist-user": "watugot",
          },
        }
      );
      // console.log(response)
      if (response.status === 200) {
        toast.success("Deleted Successfully");
        onClose();
      }
    } catch (err) {
      // console.log(err)
      if (err.response.status === 401) {
        toast.error("Not Authorized to access the info");
      } else if (err.response.status === 500) {
        toast.error("A problem with our servers, hang on");
      } else if (err.response.status === 404) {
        toast.error("user does not exist");
      } else {
        toast.error("Check Internet connection");
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="delete Modal"
        className="modal-contents "
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <main className="delete">
          <nav className="delete__delete-nav">
            <div className="delete__delete-nav__nav-title">
              <h2>Delete Confirmation</h2>
            </div>

            <div className="delete__delete-nav__nav-close">
              <AiOutlineClose
                className="delete__delete-nav__nav-close__class-icon"
                onClick={onClose}
              />
            </div>
          </nav>
          <div className="delete__delete-container">
            <div className="delete__delete-container__name">
              <label>Are you sure you wish to delete?</label>
            </div>
          </div>
          <div className="delete__okay-btn">
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                handleDelete(flagId);
              }}
            >
              Yes
            </button>
          </div>
        </main>
      </Modal>
    </>
  );
}

export default DeleteViewModel;
