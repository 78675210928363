import React from "react";
import "./Reports.scss";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { BiCheckbox } from "react-icons/bi";
import { BsArrowDownUp } from "react-icons/bs";

function Report() {
  const tableHeaders = [
    <BiCheckbox className="mother-checkbox" />,
    "Pi Username",
    "Total Orders",
    "Total Pi Items Availed",
    "Total Pi Items Sold",
    "Total Pi Items Purchased",
  ];

  return (
    <>
      <section className="report-main">
        <div className="dash-main-cont">
          <div className="dashboard-menu">
            <h2 className="dash-nav">Home</h2>
            <MdKeyboardArrowRight className="dash-nav" />
            <Link to="/reports" className="link-watugot">
              <h2 className="dash-nav">Reports</h2>
            </Link>

            <MdKeyboardArrowRight className="dash-nav" />
            <Link to="/reports" className="link-watugot">
              <h2 className="dash-nav">Users</h2>
            </Link>
          </div>

          <div className="user-section-pi">
            <div className="user-section__all-users">
              <h1 className="user-section__intro">Reports</h1>
            </div>

            <div className="user-section-pi__items">
              <div className="user-section-pi__items__sold">
                <Link
                  to="/reports"
                  className="user-section-pi__items__sold__pi-items"
                >
                  <p>Users</p>
                </Link>
              </div>

              <div className="user-section-pi__items__purchase">
                <Link
                  to="/reports-stores"
                  className="user-section-pi__items__purchase__pi-items-purchased"
                >
                  <p>Stores</p>
                </Link>
              </div>
              <div className="user-section-pi__items__purchase">
                <Link
                  to="/reports-watizens"
                  className="user-section-pi__items__purchase__pi-items-purchased"
                >
                  <p>Watizens</p>
                </Link>
              </div>
              <div className="user-section-pi__items__purchase">
                <Link
                  to="/reports-country"
                  className="user-section-pi__items__purchase__pi-items-purchased"
                >
                  <p>Country</p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="entries">
          <div className="entries__show">
            <div className="entries__show__text">
              <p className="entries__show__text__p">Show</p>
            </div>

            <div className="entries__show__group">
              <p>10</p>
              <IoIosArrowDown />
            </div>

            <div className="entries__show__text">
              <p>entries</p>
            </div>
          </div>

          <div className="entries__inputs">
            <div className="entries__location">
              <select className="country-select">
                <option id="country" value="" disabled selected>
                  Country
                </option>
                <option value="category1">Category 1</option>
                <option value="category2">Category 2</option>
                <option value="category3">Category 3</option>
              </select>
            </div>

            <div className="entries__inputs__search">
              <input placeholder="Search store name or etc." />
              <AiOutlineSearch className="entries__inputs__search__searchIcon" />
            </div>
          </div>
        </div>

        <table className="simple-table">
          <thead>
            <tr>
              {tableHeaders.map((header, index) => (
                <th key={index} className="square-header">
                  {[
                    "Total Orders",
                    "Total Pi Items Availed",
                    "Total Pi Items Sold",
                    "Total Pi Items Purchased",
                  ].includes(header) && (
                    <BsArrowDownUp className="arrow-icon" />
                  )}
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <BiCheckbox className="child-checkbox" />
              </td>
              <td>Pi Username 1</td>
              <td>25</td>
              <td>12</td>
              <td>8</td>
              <td>15</td>
            </tr>
            <tr>
              <td>
                <BiCheckbox className="child-checkbox" />
              </td>
              <td>Pi Username 2</td>
              <td>15</td>
              <td>8</td>
              <td>5</td>
              <td>10</td>
            </tr>
          </tbody>
        </table>

        <div className="pagination">
          <div className="show-entries">
            <p>Showing 1 to 10 of 430 entries</p>
          </div>

          <div className="page-pagination"></div>
        </div>
      </section>
    </>
  );
}

export default Report;
