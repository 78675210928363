import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashCompo from "./pages/Dashboard/Dash";
import Usercomponent from "./pages/Users/Users";
import PiItemsSoldComponent from "./pages/PiItems/SoldPiItems/Sold";
import PiItemsPurchaseComponent from "./pages/PiItems/PurchasedPiItems/Purchase";
import CategoryComponent from "./pages/Categories/Category";
import UsersActivity from "./pages/PiActivity/UsersActivity";
import NotificationsComponent from "./pages/Notifications/Notification";
import SettingsComponent from "./pages/Settings/Settings";
import ReportComponent from "./components/Reports/Reports";
import CountryReportComponent from "./components/Reports/CountryReport";
import ProfilesComponent from "./pages/Profile/Profile";
import Login from "./components/Login/Login";
import FlaggedPiListingComponent from "./pages/Flagged/Flagged";
// import RegisterComponent from './pages/Register/register'
// import VerifyComponent from './pages/verify/verify'
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<RegisterComponent />} /> */}
          {/* <Route path="/verify" element={<VerifyComponent />} /> */}
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<DashCompo />} />
          <Route path="/users" element={<Usercomponent />} />

          <Route path="/pi-items-sold" element={<PiItemsSoldComponent />} />
          <Route
            path="/pi-items-purchased"
            element={<PiItemsPurchaseComponent />}
          />
          <Route path="/categories" element={<CategoryComponent />} />
          <Route path="/pi-activity-users" element={<UsersActivity />} />
          <Route path="/notifications" element={<NotificationsComponent />} />
          <Route path="/settings" element={<SettingsComponent />} />
          <Route path="/reports" element={<ReportComponent />} />
          <Route path="/reports-country" element={<CountryReportComponent />} />
          <Route path="/profile" element={<ProfilesComponent />} />
          <Route path="/flagged-pi-listings" element={<FlaggedPiListingComponent />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
