import React, { useState, useEffect } from "react";
import "./EditCategory.scss";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function EditCategory({ isOpen, onClose, selectedItem, token }) {
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedItem) {
      setId(selectedItem?._id);
      setName(selectedItem?.name);
    }
  }, [selectedItem]);

  const handleUpdate = async (e) => {
    if (!name) {
      toast.error("Please fill in the field");
      return;
    }

    try {
      setLoad(true);
      const data = {
        id: id,
        name: name,
      };

      const response = await axios.patch(
        "https://www.api.watugot.net/admin/categories",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-whitelist-user": "watugot",
          },
        }
      );

      console.log(response);

      if (response.status === 200) {
        toast.success("Category was updated successfully");
        window.location.reload();
      }

      setLoad(false);
    } catch (err) {
      //   console.log(err);
      if (err.response.status === 401) {
        toast.error("Not authorized");
        navigate("/");
      } else if (err.response.status === 500) {
        toast.error("A problem with our servers, hang on");
      } else {
        toast.error("Check Internet connection");
      }
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Contact Modal"
        className="modal-contents-edit "
        overlayClassName="modal-overlay"
      >
        <main className="edit-category">
          <nav className="edit-nav">
            <div className="nav-title">
              <h2>Edit Category</h2>
            </div>

            <div className="nav-close">
              <AiOutlineClose className="class-icon" onClick={onClose} />
            </div>
          </nav>

          <form className="edit-form">
            <div className="form-group">
              <div className="id">
                <label>Id:</label>
                <input
                  type="text"
                  required
                  placeholder="id of the category"
                  className="input-id"
                  value={id || ""}
                  readOnly
                />
              </div>

              <div className="id">
                <label>Category name:</label>
                <input
                  type="text"
                  required
                  placeholder="Name of the category"
                  className="name-id-input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="form-btn">
                {load ? (
                  <AiOutlineLoading3Quarters className="loading-icon" />
                ) : (
                  <button className="okay-btn" onClick={() => handleUpdate()}>
                    Update
                  </button>
                )}
              </div>
            </div>
          </form>
        </main>
      </Modal>
    </>
  );
}

export default EditCategory;
