import React from "react";
import Nav from "../../components/Navbar/Nav";
// import Side from "../../components/sidebar/sidebar";
import Side from "../../components/SideBar/SideBar";
import Profiles from "../../components/Profile/Profile";

function ProfilePage() {
  return (
    <>
      <div className="main-container">
        <div className="side-main-container side-main-container-responsive">
          <Side />
        </div>

        <div className="nav-dash-main-container">
          <Nav />
          <Profiles />
        </div>
      </div>
    </>
  );
}

export default ProfilePage;
