import React from "react";
import Nav from "../../components/Navbar/Nav";
// import Side from "../../components/sidebar/sidebar";
import Side from "../../components/SideBar/SideBar";
import User from "../../components/Users/Users";

function UserPage() {
  return (
    <>
      <div className="main-container">
        <div className="side-main-container side-main-container-responsive">
          <Side />
        </div>

        <div className="nav-dash-main-container">
          <Nav />
          <User />
        </div>
      </div>
    </>
  );
}

export default UserPage;
