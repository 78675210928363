import React, { useState, useEffect } from "react";
import "./Dash.scss";
import { MdKeyboardArrowRight } from "react-icons/md";
import { TbWebhook } from "react-icons/tb";
import { BsBasket3Fill } from "react-icons/bs";
import { CgNotes } from "react-icons/cg";
import { SiWebmoney } from "react-icons/si";
import { PiFlagPennant } from "react-icons/pi";
import axios from "axios";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function Dash() {
  const [details, setDetails] = useState();
  // console.log(details)
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getDashDetails = async () => {
      try {
        setLoad(true);

        const token = Cookies.get().AdminToken;

        const response = await axios.get(
          "https://www.api.watugot.net/admin/dashboard",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "X-whitelist-user": "watugot",
            },
          }
        );
        console.log(response.data);
        setDetails(response.data);

        setLoad(false);
      } catch (err) {
        console.log(err);

        if (err.response.status === 401) {
          toast.error("You are Unauthorized");
          navigate("/");
        } else if (err.response.status === 500) {
          toast.error("A problem with our servers, hang on");
        } else {
          toast.error("Check your network connection");
        }
      } finally {
        setLoad(false);
      }
    };

    getDashDetails();
  }, [navigate]);

  const DashItems = [
    {
      icon: <TbWebhook className="web-hook" />,
      no: `${details?.users}`,
      title: "Total Pi Users",
    },
    {
      icon: <BsBasket3Fill className="basket-fill-1" />,
      no: `${details?.postedItems}`,
      title: "Total Items Posted",
    },
    {
      icon: <SiWebmoney className="web-money" />,
      no: `${details?.soldItems}`,
      title: "Total Items Purchased",
    },
    {
      icon: <CgNotes className="cg-notes-1" />,
      no: `${details?.totalEarned}`,
      title: "Total Pi Earned",
    },
    {
      icon: <PiFlagPennant className="people-fill-1" />,
      no: `${details?.transactionsTotalCount}`,
      title: "Total Transactions",
    },
    // {
    //   icon: <BiSolidStopwatch className="stop-watch" />,
    //   no: 23,
    //   title: "Pending Approval",
    // },
    // {
    //   icon: <FaMoneyBill className="money-bill" />,
    //   no: "283 K",
    //   title: "Total Earned",
    // },
    // {
    //   icon: <CiHome className="ci-home-1" />,
    //   no: 98,
    //   title: "Last Month Stores",
    // },
    // {
    //   icon: <FaMoneyCheck className="money-check" />,
    //   no: "592 K",
    //   title: "Last Month Earnings",
    // },
    // {
    //   icon: <BsPeopleFill className="people-fill-1" />,
    //   no: 145,
    //   title: "Last Month Watizens",
    // },
    // {
    //   icon: <BsFileBarGraph className="fill-graph-1" />,
    //   no: 10,
    //   title: "Last Month Offers",
    // },
    // {
    //   icon: <BsFileBarGraph className="fill-graph-2" />,
    //   no: 10,
    //   title: "Last Month Offers Availed",
    // },
    // {
    //   icon: <SiWebmoney className="web-money" />,
    //   no: 63,
    //   title: "Last Month Featured Stores",
    // },
    // {
    //   icon: <BsPeopleFill className="people-fill-2" />,
    //   no: 145,
    //   title: "Last Month Featured Listings",
    // },
    // {
    //   icon: <BsBasket3Fill className="cg-notes-2" />,
    //   no: 10,
    //   title: "Last Month Orders",
    // },
    // {
    //   icon: <CgNotes className="basket-fill-1" />,
    //   no: 63,
    //   title: "Active Offers",
    // },
    // {
    //   icon: <PiFlagPennant className="people-fill-1" />,
    //   no: 100,
    //   title: "Flagged Requests",
    // },
    // {
    //   icon: <BsBasket3Fill className="pi-flag" />,
    //   no: 4,
    //   title: "Featured Stores",
    // },
  ];

  DashItems.forEach((item) => {
    const key = item.title.toLowerCase().replace(/ /g, "_");
    item.no = details && details[key] !== undefined ? details[key] : item.no;
  });

  return (
    <>
      <section className="side-side-dash">
        <div className="dash-main-cont">
          <div className="dashboard-menu">
            <h2 className="dash-nav">Home</h2>
            <MdKeyboardArrowRight className="dash-nav" />
            <h2 className="dash-nav">Dashboard</h2>
          </div>

          <h1 className="dash-intro">Dashboard</h1>

          <div className="dash-body">
            {DashItems.map((details, index) => (
              <div className="dash-items" key={index}>
                <div className="dash-icons">{details.icon}</div>

                <div className="dash-text-no">
                  <p className="dash-no">
                    {load ? (
                      <AiOutlineLoading3Quarters className="loading-icon" />
                    ) : (
                      details.no
                    )}
                  </p>

                  <p className="dash-desc">{details.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Dash;
