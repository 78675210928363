import React, { useState, useEffect, useCallback } from "react";
import "./UsersActivity.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import { FaFileCsv } from "react-icons/fa";
import { CSVLink } from "react-csv";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  Table,
  TableContainer,
  TableRow,
  TablePagination,
  TableHead,
  TableCell,
  TableBody,
  Checkbox,
  TableSortLabel,
  Box,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import DownloadModel from "./DownloadView";
function UsersPiActivity() {
  let path = useLocation();
  const navigate = useNavigate();
  const [, /*load*/ setLoad] = useState(false);
  const [piUserActivity, setPiUserActivity] = useState([]);
  const [downloadModal, setDownloadModal] = useState(false);
  const [piUserActivityIndividual, setPiUserActivityIndividual] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const token = Cookies.get().AdminToken;
  const csvHeaders = [
    { label: "Product Name", key: "productName" },
    { label: "Pi Transacted", key: "amount" },
    { label: "Buyer Payment Status", key: "buyerPaymentStatus" },
    { label: "Status", key: "status" },
    { label: "Transaction ID", key: "_id" },
    { label: "Pi ID", key: "piId" },
    { label: "Receving User ID", key: "recivingUid" },
    { label: "Receving User Name", key: "recivingUser.fullName" },
    { label: "Receving User Address", key: "recivingUser.address1" },
    { label: "Receving User Email", key: "recivingUser.email" },
  ];

  //get all users pi-activity
  useEffect(() => {
    const getCategories = async () => {
      try {
        setLoad(true);

        // const token = Cookies.get().AdminToken;
        // console.log(token);

        const response = await axios.get(
          `https://www.api.watugot.net/admin/payments/${skip}/${limit}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "X-whitelist-user": "watugot",
            },
          }
        );

        // console.log(response.data.response);
        const data = response.data.payments;
        setPiUserActivity(data);
        setLoad(false);
      } catch (err) {
        // console.log(err);
        if (err.response?.status === 401) {
          toast.error("Not authorized");
          navigate("/");
        } else if (err.response?.status === 500) {
          toast.error("A problem with our servers, hang on");
        } else {
          toast.error("Check Internet connection");
        }
      } finally {
        setLoad(false);
      }
    };

    getCategories();
  }, [navigate, token, skip, limit]);

  const downloadCsv = async (userId) => {
    try {
      const pdf = await axios.get(
        `https://www.api.watugot.net/admin/payments/user/bought/${userId}/0/10`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-whitelist-user": "watugot",
          },
        }
      );
      setPiUserActivityIndividual(pdf.data.payments);
      if (pdf.data.payments.length > 0) {
        OpenModal(pdf.data.payments);
      } else {
        toast.error("No Invoice found");
      }
      console.log(pdf.data.payments);
    } catch (err) {
      if (err.response?.status === 401) {
        toast.error("Not authorized");
        navigate("/");
      } else if (err.response?.status === 500) {
        toast.error("A problem with our servers, hang on");
      } else {
        toast.error("Check Internet connection");
      }
    } finally {
      setLoad(false);
    }
  };
  const OpenModal = (user) => {
    setDownloadModal(true);
    setPiUserActivityIndividual(user);
  };

  const CloseModal = () => {
    setDownloadModal(false);
  };
  //table integration
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("date");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = useCallback((order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, []);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  //table head
  const headCells = [
    {
      id: "recivingUser.fullName",
      label: "Recevier's Pi Username",
    },
    {
      id: "sendingUser.fullName",
      label: "Sender's Pi Username",
    },
    {
      id: "amount",
      label: "Pi Transacted",
    },
    // {
    //   id: "reason",
    //   label: "Reason",
    // },
    {
      id: "date",
      label: "Date",
    },
    {
      id: "_id",
      label: "Transaction ID",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "action",
      label: "Download Invoice",
    },
  ];
  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow className="table-header">
          <TableCell className="table-cell">
            <Checkbox
              // color="primary"
              className="checkbox"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "Select All",
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              className="header-label"
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  //search, sort, pagination functionality

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = piUserActivity.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    console.log("this is new page", newPage);
    console.log("this is page", page);
    console.log("this is skip", skip);
    console.log("this is limit", limit);

    setPage(newPage);
    if (newPage > page) {
      const newSkip = skip + 10;
      const newLimit = limit + 10;
      setSkip(newSkip);
      setLimit(newLimit);
    } else {
      const newSkip = skip - 10;
      const newLimit = limit - 10;
      setSkip(newSkip);
      setLimit(newLimit);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const innerMemoFunc = useCallback(() => {
    const arr = stableSort(piUserActivity, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    const newRows = arr.filter((el, i) => {
      const searchMatches =
        el?.sendingUser?.fullName
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        el?.recivingUser?.fullName
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase());

      return searchMatches;
    });
    return newRows;
  }, [
    order,
    orderBy,
    page,
    rowsPerPage,
    piUserActivity,
    searchQuery,
    getComparator,
  ]);

  // final array of data to pass in table body
  const visibleRows = React.useMemo(() => innerMemoFunc(), [innerMemoFunc]);
  return (
    <>
      <section className="side-side-dash">
        <div className="dash-main-cont">
          <div className="dashboard-menu">
            <h2 className="dash-nav">Home</h2>
            <MdKeyboardArrowRight className="dash-nav" />
            <Link to="/piactivity" className="link-watugot">
              <h2 className="dash-nav">Pi Activity</h2>
            </Link>
            <MdKeyboardArrowRight className="dash-nav" />
            <h2 className="dash-nav">Users</h2>
          </div>

          <div className="activity-nav-section">
            <div className="user-section__all-users">
              <h1 className="user-section__intro">Pi Activity</h1>
            </div>

            <div className="nav-section">
              <div
                className={
                  path.pathname === "/pi-activity-users"
                    ? "nav-head-selected"
                    : "nav-head"
                }
              >
                <Link to="/pi-activity-users" className="nav-head-label">
                  <p>Users</p>
                </Link>
              </div>

              <div
                className={
                  path.pathname === "/pi-activity-stores"
                    ? "nav-head-selected"
                    : "nav-head"
                }
              >
                <Link to="" className="nav-head-label">
                  <p>Stores</p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="entries-activity">
          <div className="entries-activity__inputs">
            <div className="entries-activity__inputs__search">
              <input
                placeholder="Search store name or etc."
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
              <AiOutlineSearch className="entries-activity__inputs__search-searchIcon" />
            </div>
            <div className="entries-activity__csv-div">
              <CSVLink
                className="entries-activity__csv-div__csv-button"
                data={piUserActivity}
                headers={csvHeaders}
                filename={"Users-Pi-Activity.csv"}
              >
                <FaFileCsv />
                Export to CSV
              </CSVLink>
            </div>
          </div>
        </div>

        <div className="table-container">
          <TableContainer>
            <Table className="data-table">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={piUserActivity.length}
              />
              <TableBody>
                {visibleRows?.map((rowData, index) => {
                  const isItemSelected = isSelected(rowData._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      className="table-row"
                      key={rowData._id}
                      hover
                      onClick={(event) => handleClick(event, rowData._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell
                        key="checkbox"
                        className="table__container__data-table__checkbox-cell__checkbox-cell"
                      >
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell className="table-data">
                        {rowData?.recivingUser?.fullName}
                      </TableCell>
                      <TableCell className="table-data">
                        {rowData?.sendingUser?.fullName}
                      </TableCell>
                      <TableCell className="table-data">
                        {rowData?.amount}
                      </TableCell>
                      <TableCell className="table-data">
                        {new Date(rowData?.createdAt).toLocaleDateString()}
                      </TableCell>
                      <TableCell className="table-data">
                        {rowData?._id}
                      </TableCell>
                      <TableCell>{rowData.status}</TableCell>
                      <TableCell className="square-cell">
                        <div
                          className="action-column-activity"
                          onClick={() => {
                            downloadCsv(rowData.userId);
                          }}
                        >
                          <FaFileCsv className="delete-icon" /> CSV
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={piUserActivity?.length + 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </section>
      <DownloadModel
        isOpen={downloadModal}
        onClose={CloseModal}
        piUserActivityIndividual={piUserActivityIndividual}
      />
    </>
  );
}

export default UsersPiActivity;
