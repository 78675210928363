import React, { useState, useEffect } from "react";
import "./SoldView.scss";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";

function UserSoldView({ isOpen, onClose, productId }) {
  const [productDetails, setProductDetails] = useState([]);
  const [sellerDetails, setSellerDetails] = useState([]);
  const [, /*load*/ setLoad] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen === true) {
      const getProductDetails = async () => {
        try {
          setLoad(true);

          const token = Cookies.get().AdminToken;

          const response = await axios.get(
            `https://www.api.watugot.net/admin/products/${productId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "X-whitelist-user": "watugot",
              },
            }
          );

          // console.log(response.data.productObj);
          // console.log(response.data.sellerData);
          const productData = response.data.productObj;
          const sellerData = response.data.sellerData;
          setProductDetails(productData);
          setSellerDetails(sellerData);

          setLoad(false);
        } catch (err) {
          // console.log(err);
          if (err.response?.status === 401) {
            toast.error("Not authorized");
            navigate("/");
          } else if (err.response?.status === 500) {
            toast.error("A problem with our servers, hang on");
          } else {
            // toast.error("Check Internet connection");
          }
        } finally {
          setLoad(false);
        }
      };
      getProductDetails();
    }
  }, [productId, navigate, isOpen]);
  if (!isOpen) {
    return null; // Don't render the modal if it's closed
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Contact Modal"
        className="modal-contents-sold-view"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <main className="sold-view">
          <nav className="sold-view__sold-view-nav">
            <div className="sold-view__sold-view-nav__nav-title">
              <h2>Detail View</h2>
            </div>

            <div className="sold-view__sold-view-nav__nav-close">
              <AiOutlineClose
                className="sold-view__sold-view-nav__nav-close__class-icon"
                onClick={onClose}
              />
            </div>
          </nav>
          <div className="sold-view__sold-view-container">
            <div className="sold-view__sold-view-container__name">
              <label>Product Name</label>
              <input
                type="text"
                defaultValue={productDetails?.title}
                readOnly
              />
            </div>

            <div className="sold-view__sold-view-container__name">
              <label>Description</label>
              <input
                type="text"
                defaultValue={productDetails?.description}
                readOnly
              />
            </div>

            <div className="sold-view__sold-view-container__name">
              <label>Price</label>
              <input
                type="text"
                defaultValue={productDetails?.price}
                readOnly
              />
            </div>
            <div className="sold-view__sold-view-container__name">
              <label>Condition</label>
              <input
                type="text"
                defaultValue={productDetails?.condition}
                readOnly
              />
            </div>

            <label>
              <b>Seller Details</b>
            </label>

            <div className="sold-view__sold-view-container__name">
              <label> Name</label>
              <input type="text" defaultValue={sellerDetails?.name} readOnly />
            </div>
            <div className="sold-view__sold-view-container__name">
              <label> Rating</label>
              <input
                type="text"
                defaultValue={sellerDetails?.rating}
                readOnly
              />
            </div>
            <div className="sold-view__sold-view-container__name">
              <label>Review Count</label>
              <input
                type="text"
                defaultValue={sellerDetails?.reviewsCount}
                readOnly
              />
            </div>
          </div>

          <div className="sold-view__okay-btn">
            <button onClick={onClose}>Close</button>
          </div>
        </main>
      </Modal>
    </>
  );
}

export default UserSoldView;
