import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Dropdown, MenuProps } from "antd";
import {
  AlignCenterOutlined,
  AppstoreOutlined,
  BlockOutlined,
  CheckOutlined,
  CreditCardOutlined,
  DashOutlined,
  FileTextOutlined,
  FlagOutlined,
  FundOutlined,
  HomeOutlined,
  KeyOutlined,
  MessageOutlined,
  NotificationOutlined,
  SettingOutlined,
  ShopOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

//import logo from "../../assets/Watugot.png"
import { PATHS } from "../../constants/paths";
import { store } from "../../store/index.store";
//import avatar from "../../assets/DefaultAvatar.png";

import "./SideBar.scss";

const items: MenuProps["items"] = [
  {
    key: "1",
    label: (
      <a rel="noopener noreferrer" href="/">
        My Profile
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a rel="noopener noreferrer" href="/">
        Log Out
      </a>
    ),
  },
];

const bodyItems: { name: string; icon: any; href: string }[] = [
  {
    name: "Dashboard",
    icon: <HomeOutlined />,
    href: PATHS.DASHBOARD,
  },
  {
    name: "Approval",
    icon: <CheckOutlined />,
    href: PATHS.APPROVAL,
  },
  {
    name: "Users",
    icon: <UsergroupAddOutlined />,
    href: PATHS.USERS,
  },
  {
    name: "Stores",
    icon: <ShopOutlined />,
    href: PATHS.STORES,
  },
  {
    name: "Watizens",
    icon: <UsergroupAddOutlined />,
    href: PATHS.WATIZENS,
  },
  {
    name: "Categories",
    icon: <AppstoreOutlined />,
    href: PATHS.CATEGORIES,
  },
  {
    name: "PI Activity",
    icon: <FundOutlined />,
    href: PATHS.PI_ACTIVITY,
  },
  {
    name: "Flagged",
    icon: <FlagOutlined />,
    href: PATHS.FLAGGED,
  },
  {
    name: "Notification",
    icon: <NotificationOutlined />,
    href: PATHS.NOTIFICATION,
  },
  {
    name: "Messages",
    icon: <MessageOutlined />,
    href: PATHS.MESSAGES,
  },
  {
    name: "Featured",
    icon: <BlockOutlined />,
    href: PATHS.FEATURED,
  },
  {
    name: "Settings",
    icon: <SettingOutlined />,
    href: PATHS.SETTINGS,
  },
  {
    name: "Permissions",
    icon: <KeyOutlined />,
    href: PATHS.PERMISSIONS,
  },
  {
    name: "Billing & Payments",
    icon: <CreditCardOutlined />,
    href: PATHS.BILLING_PAYMENTS,
  },
  {
    name: "Reports",
    icon: <FileTextOutlined />,
    href: PATHS.REPORTS,
  },
];

export const SideBar = observer(() => {
  const { UIStore } = store;

  const location = useLocation();

  return (
    <div className="c-side-bar">
      <div className="c-side-bar__header">
        <img /*src={logo}*/ alt="logo" className="c-side-bar__header-logo" />
        <AlignCenterOutlined
          onClick={() => {
            UIStore.toggleSidebar();
          }}
        />
      </div>
      <div className="c-side-bar__body">
        {bodyItems.map((item, index) => {
          const isActive = location.pathname.includes(item.href);
          return (
            <Link
              to={item.href}
              key={index}
              className={`c-side-bar__body-item ${
                isActive && " c-side-bar__body-item--active"
              }`}
            >
              <span className="c-side-bar__body-item-icon">{item.icon}</span>
              <span className="c-side-bar__body-item-name">{item.name}</span>
            </Link>
          );
        })}
      </div>
      <div className="c-side-bar__footer">
        <img
          // src={avatar}
          alt="avatar"
          className="c-side-bar__footer-user-avatar"
        />
        <div className="c-side-bar__footer-user-details">
          <p className="c-side-bar__footer-user-details-name">John Doe</p>
          <p className="c-side-bar__footer-user-details-email">
            john.doe@idk.org
          </p>
        </div>
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="topRight"
          arrow
        >
          <DashOutlined className="c-side-bar__footer-menu" />
        </Dropdown>
      </div>
    </div>
  );
});

export default SideBar;
